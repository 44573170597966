import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "this-left-panel-is-part-of-the-carbon-ui-shell-a-shell-is-a-collection-of-components-shared-by-all-products-within-a-platform-it-provides-a-common-set-of-interaction-patterns-that-persist-between-and-across-products"
    }}>{`This left panel is part of the Carbon UI shell. A shell is a collection of components shared by all products within a platform. It provides a common set of interaction patterns that persist between and across products.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">General guidance</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Anatomy</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Behavior</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="tile--resource--no-margin tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="UI Shell template" href="https://sketch.cloud/s/EjVmA" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png",
              "srcSet": ["/static/aeb99a8308cb0fc718bd4c42a1a0aba3/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
    </Row>
    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p>{`The UI shell is made up of three components: The `}<a parentName="p" {...{
        "href": "/components/UI-shell-header/usage"
      }}>{`header`}</a>{`, the left panel, and the `}<a parentName="p" {...{
        "href": "/components/UI-shell-right-panel/usage"
      }}>{`right panel`}</a>{`. All three can be used independently, but the components were designed to work together.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shell UI component`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Header`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The highest level of navigation. The header can be used on its own for simple products or be used to trigger the left and right panels.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Left panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional panel that is used for a product’s navigation.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Right panel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`An optional panel that shows additional system level actions or content associated with a system icon in the header.`}</td>
        </tr>
      </tbody>
    </table>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "83.42391304347827%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "UI shell components.",
        "title": "UI shell components.",
        "src": "/static/be299d265c24f9028bcc0646d8bfd634/fb070/shell-usage-1.png",
        "srcSet": ["/static/be299d265c24f9028bcc0646d8bfd634/d6747/shell-usage-1.png 288w", "/static/be299d265c24f9028bcc0646d8bfd634/09548/shell-usage-1.png 576w", "/static/be299d265c24f9028bcc0646d8bfd634/fb070/shell-usage-1.png 1152w", "/static/be299d265c24f9028bcc0646d8bfd634/c3e47/shell-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "anatomy"
    }}>{`Anatomy`}</h2>
    <p>{`The left panel contains secondary navigation and is positioned below the header and fixed to the left. Both links and sub-menus can be used in the side-nav and may be mixed together.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "75.81521739130434%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Left panel with nested sub-menus.",
        "title": "Left panel with nested sub-menus.",
        "src": "/static/2df1e8225d41343a0ee1245e6b2d3609/fb070/left-panel-usage-1.png",
        "srcSet": ["/static/2df1e8225d41343a0ee1245e6b2d3609/d6747/left-panel-usage-1.png 288w", "/static/2df1e8225d41343a0ee1245e6b2d3609/09548/left-panel-usage-1.png 576w", "/static/2df1e8225d41343a0ee1245e6b2d3609/fb070/left-panel-usage-1.png 1152w", "/static/2df1e8225d41343a0ee1245e6b2d3609/c3e47/left-panel-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>
    <h2 {...{
      "id": "behavior"
    }}>{`Behavior`}</h2>
    <p>{`Use the left panel if there are more than five secondary navigation items, or if you expect a user to switch between secondary items frequently. Sub-menus are denoted with a chevron and expand when clicked, pushing the other items down in the panel. To collapse the sub-menu, the user must again click the menu header in the left panel.`}</p>
    <p>{`The left panel does not support three tiers of navigation. If you have additional content to display beneath a sub-menu, use tabs within the page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      